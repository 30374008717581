import { Injectable } from '@angular/core';
import { RequestService } from '../services/shared/request.service';
import { environment } from '../../../environments/environment';
import { BaseModel } from '../../core/models/baseModel';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { Feature } from '../models/feature';

@Injectable()
export class FeatureService {
	private features = new BehaviorSubject(new Array<Feature>());
	selectedFeatures = this.features.asObservable();
	constructor(
		private _requestService: RequestService) {
	}
	saveSelectedFeatures(requests: Array<Feature>) {
		this.features.next(requests);
	}
	getChilds<T extends BaseModel>(id: number) {
		return this._requestService.SendRequest
			('GET', environment.apiBaseURL + 'get-childs/' + id , null, null);
	}
}
