// USA
export const locale = {
    lang: 'ar',
    data: {
        STYLELINK: {
            CSS: './assets/demo/default/base/style.bundle.rtl.css',
        },
        TRANSLATOR: {
            SELECT: 'اختار اللغة',
        },
        AUTH: {
            GENERAL: {
                BRANCH: 'الفرع',
                FROM_STORE: 'الاستلام من الفرع',
                DELIVERY: 'التوصيل',
                DELIVERY_NOTE: 'مصاريف التوصيل : ',
                SOON: 'Soon',
                OR: 'أو',
                SUBMIT_BUTTON: 'موافق',
                WELCOMETXT: 'مرحبا بك فى مشار',
                BACK: 'رجوع',
                RESET_BUTTON: 'تغيير كلمة المرور',
                ACTIVATE: 'حسابك غير مفعل ',
                ACTIVATENOW: 'تفعيل الحساب',
                ACTIVATETOORDER: 'حسابك غير مفعل , برجاء تفعيل الحساب لتتمكن من تأكيد الطلب',
                PROCEED_TO_PAYMENT: 'الذهاب إلى الدفع',
                DELIVERY_INTRUCTIONS_DETAILS: 'تفاصيل تعليمات الإستلام',

            },
            RESET: {
                TITLE: 'تغيير كلمة المرور؟',
                DESC: 'Enter your new password',
            },
            INPUT: {
                EMAIL: 'البريد الإلكترونى',
                PASSWORD: 'كلمة المرور',
                CONFIRM_PASSWORD: 'تأكيد كلمة المرور',
            },
            REGISTER: {
                SUCCESS: 'تم انشاء حسابك بنجاح, برجاء التوجه لاقرب فرع لاخذ مقاساتك',
                NAME_TXT: 'الاسم',
                EMAILTXT: 'البريد الالكترونى',
                PHONE_TXT: 'رقم الجوال',
                CITY_TXT: 'المدينة',
                ADDRESS_TXT: 'العنوان',
                PASSWORD_TXT: 'كلمة المرور',
                CONFIRM_PASSWORD: 'تأكيد كلمة المرور',
                HAVE_ACCOUNT: 'لديك حساب؟',
                LOGIN: 'تسجيل الدخول',
                VALIDATION: {
                    REQUIRED_NAME: 'الاسم مطلوب',
                    Requird_Email: 'البريد الالكترونى مطلوب',
                    Invaild_Email: 'البريد الالكترونى غير صحيح',
                    REQUIRED_PHONE: 'الهاتف مطلوب',
                    REQUIRED_CITY: 'المدينة مطلوبه',
                    REQUIRED_BRANCH: 'الفرع مطلوبه',
                    REQUIRED_ADDRESS: 'العنوان مطلوب',
                    REQUIRED_PASSWORD: 'كلمه المرور مطلوبه',
                    REQUIRED_CONFIRM_PASSWORD: 'تاكيد كلمه المرور مطلوب',
                    INVALID_CONFIRM_PASSWORD: 'غير متطابق مع كلمه المرور',
                    Invaild_PASSWORD: 'كلمة المرور يجب ان لا تقل عن 6 حروف او ارقام'
                }
            },
            LOGIN: {
                EMAILTXT: 'البريد الالكترونى',
                PASSWORDTXT: 'كلمة المرور',
                FORGOTPASSWORDTXT: 'هل نسيت كلمة المرور؟',
                NOT_HAVE_ACCOUNT: 'ليس لديك حساب؟',
                CREATE_ACCOUNT: 'إنشاء حساب'
            },
            FORGETPASSWORD: {
                TITLE: 'هل نسيت كلمة السر ؟',
                Message_Txt: 'أدخل بريدك الاكترونى ليصلك ايميل لتغيير كلمة السر',
                EMAIL_TXT: 'البريد الالكترونى',
                EMAILSENT: 'تم إرسال بريد إلكتروني ، يرجى التحقق من بريدك الإلكتروني',
                NOTREGISTERD: 'هذا البريد الإلكتروني غير مستخدم من قبل',
            },
            VALIDATION: {
                INVALID: '{{name}} غير صحيح',
                REQUIRED: '{{name}} مطلوب',
                INVALID_LOGIN: 'البريد الالكتورني او كلمة المرور خاطئة',
                EMAIL_TXT: 'البريد الإلكترونى مطلوب',
                EMAIL_TXT_INVALID: 'البريد الالكترونى غير صحيح',
                BLOCKED: 'تم حظر حسابك ، يرجى الاتصال بالدعم لمزيد من المعلومات'
            },
        },
        PHONE_VERIFICATION: {
            ACCOUNT_VERIFY: 'تأكيد الحساب',
            ADD_CODE: 'يرجى إدخال كود التأكيدالذى تم إرساله إالى هاتفك',
            VALIDATION: {
                CODE: 'يرجي ادخال الكود الذي تم ارساله لهاتفك',
            },
            VER_CODE: 'كود التأكيد',
            RESEND_CODE: 'تم اعاده ارسال كود التفعيل',
        },
        GENERAL: {
            CURRENCY: 'ر.س',
            FABRICS_TXT: 'الأقمشة',
            CANCEL: 'إلغاء',
            DELETE: 'حذف',
            HERE_TXT: 'هنا',
            RECOMMENDED: 'مقترح',
            ITEMSPERPAGE: 'عدد العناصر :',
            NEXTPAGELABEL: 'التالي',
            PREVIOUSPAGELABEL: 'السابق',
            FIRSTPAGELABEL: 'الصفحة الأولى',
            LASTPAGELABEL: 'الصفحة الأخيرة',
            RANGELABEL: 'من',
            SOON: 'قريبا',
            CONFIRM_UPDATE: 'تم تعديل البيانات بنجاح',
            PAY_ORDER: 'تم عمل طلب بنجاح',
            NO_DATA: 'لا توجد بيانات',
            CONFIRM: 'تأكيد',
            METER: 'م',
            DUEDATE: 'تاريخ التسليم: في غضون {{name}} يومًا'
        },
        BUTTONS: {
            SEARCH: 'بحث',
            RESET: 'إعادة',
            ADD: 'اضف',
            BACK: 'رجوع',
            CANCEL: 'الغاء',
            DELETE: 'حذف',
            ACCEPT: 'قبول',
            REJECT: 'رفض',
            MESSAGES: 'الرسائل',
            JOBS: 'الإرتباطات',
            SAVE: 'حفظ',
            SEND: 'إرسال',
            DOWNLOAD: 'تنزيل',
            CLOSE: 'اغلاق',
            CANCEL_JOB: 'الغاء الارتباط',
            CLOSE_JOB: 'اغلاق الارتباط',
            CREATE_ACCOUNT: 'انشاء حساب',
            LOG_IN: ' تسجيل الدخول',
            AGREE: 'موافق',
            CHANGE_PASSWORD: 'غير كلمة السر',
            EDIT: 'تعديل',
            ACCEPT_TRANSFER: 'تأكيد وصول التحويل',
            REJECT_TRANSFER: 'رفض التحويل',
            CONFIRM: 'تأكيد',
            RESEND_CODE: 'إعادة إرسال الكود',
            EDIT_PRICE: 'تعديل السعر',
            SHOW_PRICE: ' عرض السعر',
            SEND_PRICE: 'إرسال عرض السعر',
            SEND_JOB: 'طلب إرتباط',
            SEND_JOB_REQUEST: 'إرسال طلب إرتباط',
            ADD_TRANSFER: 'أضف تحويل',
            SEND_JOB_: 'إرسال الطلب',
        },
        LABELS: {
        },
        PROFILE: {
        },
        LOGIN: {
        },
        REGISTER: {
        },
        FORGOT_PASSWORD: {
            CHANGE_PASSWORD: 'تغيير كلمة السر ',

        },
        Header: {
            navbar: {
                HomeTxt: 'الرئيسية',
                CustomizeThobeTxt: 'صمم ثوبك',
                ProductsTxt: 'المنتجات',
                AboutTxt: 'عن مشار',
                BranchesTxt: 'فروعنا',
                ContactusTxt: 'اتصل بنا',
            },
            UserMenu: {
                ProfileTxt: 'حسابى الشخصى',
                OrdersTxt: 'طلباتى',
                LogoutTxt: 'تسجيل الخروج',
                LoginTxt: 'تسجيل الدخول',
                ActivateTxt: 'تفعيل الحساب',
                MEASUREMENTS: 'مقاساتى',
            }
        },
        Home: {
            bannerBtnTxt: 'صمم ثوبك بنفسك',
            NOTE: ' أنت لم تقم بتسجيل الدخول, من فضلك قم بالتسجيل لتتمكن من عمل طلبك',
            stepsInfoSection: {
                chooseFabricsTxt: 'اختر القماش',
                CustomizeThobeDescTxt: 'فقط مع مشار يمكنك تصميم ثوبك بنفسك لتحصل على قطعة منفردة ومميزة صممت خصيصا لك',
                chooseSizeTxt: 'اختر مقاسك',
                startNowBtnTxt: 'ابدأ الآن',
                howCustomizeThobe: 'كيف تصمم ثوبك',
                makeThobeTxt: 'فصل ثوبك بنفسك',
                payCreditTxt: 'ادفع ببطاقة الائتمان',
                receiveThobeTxt: 'استلم ثوبك',
                branches: 'فروعنا'
            },
            bestsellingTxt: 'الأفضل مبيعاً',
        },
        footer: {
            copyRightTxt: 'مشار كل الحقوق محفوظة.'
        },
        Product: {
            list: {
                headerTxt: 'الثياب'
            },
            IMAGE_WARNING: 'يرجى العلم بأن الصورة ليست 100٪ مثل التوب الحقيقي.',
            Recommended_TXT: 'الأقمشة المقترحة',
            NOT_AVAILABLE: 'غير متوفر',
            PRODUCT_WARNING: 'يرجى العلم بأنه لا يوجد أقمشة الان . يمكنك التواصل مع المسؤل.',
        },
        contact: {
            contactUstxt: 'اتصل بنا',
            NameTxt: 'الاسم',
            RequiredNameTxt: 'الاسم مطلوب',
            EmailTxt: 'البريد الالكترونى',
            RequirdEmailTxt: 'البريد الالكترونى مطلوب',
            InvaildEmailTxt: 'البريد الالكترونى غير صحيح',
            MessageTxt: 'الرسالة',
            RequirdMsgTxt: 'الرساله مطلوب',
            SendBtnTxt: 'ارسال',
            SUCCESS_MSG: 'تم ارسال الرساله بنجاح'
        },
        CART: {
            TITLE: 'عربة التسوق',
            PAYMENT: 'إدفع',
            VISA: 'VISA, MASTER',
            MADA: 'MADA',
            PC: 'ق',
            customer_discount: 'خصم العميل',
            PLEASE_WAIT: ' من فضلك انتظر ',
            VAT: 'الضريبة',
            TOTAL_BEFORE: 'المجموع قبل الضريبة',
            SUCCESS_PAYMENT: 'عملية دفع ناجحة',
            CHOOSE_PAYMENT_METHOD: 'اختر طريقة الدفع',
            SUCCESS_PAYMENT_MESSAGE: 'تم الدفع بنجاح، شكرا للتسوق. يمكنك مراقبة طلبك والتحقق من حالة التسليم من',
            FAILED_PAYMENT: 'عملية دفع غير ناجحة ',
            PAY_ERROR: 'لا يمكنك دفع هذا الطلب',
            EMPTY_CART_TXT: 'ليس لديك أى أثواب فى عربة التسوق',
            ADD_THOBE: 'إضافة ثوب',
            ORDER_DETAIL: 'تفاصيل الطلب',
            TOTAL_BEFORE_DELIVERY: 'المبلغ قبل التوصيل',
            DELIVERY: 'التوصيل',
            TOTAL_PRICE: 'المبلغ الكلى',
            PAY_NOW: 'أدفع الآن',
            ADD_TO_CART: 'أضف إلى عربة التسوق',
            REORDER: 'إعادة الطلب',
            DELIVERY_INSTRUCTION: 'تعليمات الإستلام',
            THOBE: 'ثوب',
            REQUIRED: 'مطلوب',
            ADD_NEW_THOBE: 'إضافة ثوب جديد',
            CANCEL_ORDER: 'إلغاء الطلب',
            CONFIRM: 'تأكيد الطلب',
            QUANTITY_TXT: 'الكمية',
            DELETE_ITEM: 'حذف توب',
            DELETE_ITEM_CONFIRM: 'هل انت متاكد من حذف هذا التوب؟',
            DELETING_ITEM: 'جاري الحذف .....',
            CONFIRM_ORDER: 'تم تأكيد طلبك',
            CONFIRM_ORDER_DESC: 'شكرا للتسوق. يمكنك مراقبة طلبك والتحقق من حالة التسليم من',
            PAYMENT_NOT_WORKING: 'الدفع الالكترونى غير متاح حاليا، سنتواصل معك لطرق الدفع',
            DETAILS: "عرض التفاصيل",
            JOB: 'مهمة',
        },
        ORDERS: {
            ORDER_DETAILS: 'تفاصيل الطلب',
            QUANTITY: 'الكمية: ',
            MY_ORDERS: 'طلبات الموقع',
            STORE_ORDERS: 'طلبات الفروع',
            CANCEL: {
                TITLE: 'إلغاء الطلب',
                DESCRIPTION: 'هل أنت متأكد من إلغاء هذا الطلب؟',
                WAIT_DESCRIPTION: 'جاري إلغاء الطلب ....',
            },
            REORDER: {
                TITLE: 'إعادة الطلب',
                DESCRIPTION: 'هل أنت متأكد من إعادة هذا الطلب؟',
                WAIT_DESCRIPTION: 'جاري إعادة الطلب ....',
            }
        },
        ABOUT: {
            ABOUT_MASHAR: 'عن مشار',
            WHY_MASHAR: 'لماذا مشار؟',
            WHY_MASHAR_FIRST_PARAGRAPH: 'مشار للخياطة الرجالية واحد من أشهر الأسماء في عالم الخياطة الرجالية استطاع عبر شبكة فروعه تقديم أرقى الخدمات فيما يتصل بخياطة الثوب الرجالي. وقد تأتى له ذلك بضخامة كوادره الإدارية والفنية التي يقودها مهندس أحب صناعة الثوب فاستثمر فيها وجعلها مهنة .',
            WHY_MASHAR_SECON_PARAGRAPH: 'ذلك هو المهندس سعود بن علي الصقية مدير عام مؤسسة مشار الذي تحدث موضحاً أن المؤسسة ومنذ بدايتها وهي في تطور مستمر تنشد الأحسن دائماً حيث أعدت عدتها لذلك من جلب أحدث الآلات والمعدات بالإضافة إلى استيراد أفخر أنواع الأقمشة. وأضاف الصقية قائلاً: يرتكز مشار على قاعدة صلبة من الخبرة الفنية تتمثل في كوادر الموظفين الذين يزيد عددهم على 300 بين إداري وفني،',
            NEW_ERA_TITLE: 'عصر جديد للثوب السعودى',
            NEW_ERA_FIRST_PARAGRAPH: 'المؤسسة ومنذ بدايتها وهي في تطور مستمر تنشد الأحسن دائماً حيث أعدت عدتها لذلك من جلب أحدث الآلات والمعدات بالإضافة إلى استيراد أفخر أنواع الأقمشة. وأضاف الصقية قائلاً: يرتكز مشار على قاعدة صلبة من الخبرة الفنية تتمثل في كوادر الموظفين الذين يزيد عددهم على 300 بين إداري وفني،',
            NEW_ERA_SECON_PARAGRAPH: 'وعن سؤالنا حول تدخل مشار في تطوير مفاهيم الثوب من حيث التفصيل والتصميم أجاب المهندس سعود: دائماً نحن نعدل ونبدل ونطور في مفاهيم الثوب وفق خبرتنا في هذا المجال بالإضافة إلى أننا آذان صاغية ننفذ رغبات العميل بدقة ونقدم له النصح في حال رؤيتنا أن جانباً من التصميم سيضر بشكل الثوب وبالتالي يتضرر العميل.',
        },
        ORDER_SUMMERY: {
            ADD_OTHER_THOBE: 'إضافة ثوب آخر',
            NOT_HAS_SIZE: 'ليس لديك مقاس مسجل لدينا، برجاء التوجه لاقرب فرع لاخذ مقاساتك',
            EDIT_SIZE_TXT: 'لتعديل المقاس يرجى زيارة اقرب فرع',
            MEASUREMENT_DETAIL: 'تفاصيل مقاسك',
            LAST_SIZE: 'آخر مقاس تم قياسه فى',
            LENGTH: 'الطول',
            SHOULDER: 'الكتف',
            WAIST: 'الخصر',
            SLEEVE: 'الكم',
            NECK: 'الرقبة',
            CM: 'سم',
            ORDER_SUBMITTED: 'تم إضافة الثوب بنجاح',
            ORDER_SUBMITTED_NOTE: 'سوف نقوم بتوصيل طلبك قريباََ',
            NOT_HAS_REGISTERED_SIZE: 'ليس لديك مقاس مسجل لدينا',
            NOT_HAS_REGISTERED_SIZE_DESC: 'برجاء زيارة اقرب فرع لأخذ مقاس بواسطة خياط متخصص لتحصل على أعلى دقه سنقوم بالاحتفاظ بتفاصيل ثوبك لدينا لحين اخذ المقاس من الفرع',
            NAME: 'اسم المنتج',
            PRICE: 'السعر',
            QUANTITY: 'الكمية',
            TOTAL: 'السعر الاجمالى',
        },
        MEASUREMENTS: {
            MY_MEASUREMENTS: 'مقاساتى',
            MEASUREMENTS_NOTE: 'هذا الثوب سيتم تصميمه وفقاَ للمقاسات المسجلة لدينا: مراجعة المقاسات',
            UNIT: 'سم',
            flat_armfit: 'الإبط',
            flat_back: 'الظهر',
            flat_chest: 'الصدر',
            flat_first_elbow: 'الكوع 1',
            flat_front: 'الجبهة',
            flat_hips: 'الفخذين',
            flat_neck: 'العنق',
            flat_shoulder: 'الكتف',
            flat_sleeve: 'الكم',
            flat_wrist: 'المعصم',
            flat_waist: 'الخصر',
            flat_second_elbow: 'الكوع 2',

        }
    }
};

