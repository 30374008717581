import { Injectable } from '@angular/core';
import { RequestService } from '../services/shared/request.service';
import { environment } from '../../../environments/environment';
import { BaseModel } from '../../core/models/baseModel';
import { Order } from '../models/order';
import { FilterObject } from '../models/filter-object';
import { Observable } from 'rxjs';

@Injectable()
export class OrderService {

  public tempOrder: Order;
  constructor(
    private _requestService: RequestService) {
  }
  pendding<T extends BaseModel>() {
    return this._requestService.SendRequest
      ('GET', environment.apiBaseURL + 'orders/pendding', null, null);
  }

  myOrders<T extends BaseModel>() {
    return this._requestService.SendRequest
      ('GET', environment.apiBaseURL + 'my-orders', null, null);
  }

  myOrder<T extends BaseModel>(id: number) {
    return this._requestService.SendRequest
      ('GET', environment.apiBaseURL + 'my-order/' + id, null, null);
  }

  cancle<T extends BaseModel>(id: number) {
    return this._requestService.SendRequest
      ('POST', environment.apiBaseURL + 'orders/' + id + '/cancle', null, null);
  }

  deleteItem<T extends BaseModel>(id: number) {
    return this._requestService.SendRequest
      ('DELETE', environment.apiBaseURL + 'order-details/' + id, null, null);
  }

  penddingCount<T extends BaseModel>() {
    return this._requestService.SendRequest
      ('GET', environment.apiBaseURL + 'orders/penddingCount', null, null);
  }

  PaginatedList<T extends BaseModel>(filterObject: FilterObject): Observable<T> {
    filterObject.PageSize = environment.pageSize;
    return this._requestService.SendRequest('POST', environment.apiBaseURL + 'my-orders/filtered-list', filterObject, null);
  }

  setTempOrder(temp: Order) {
    this.tempOrder = temp;
  }
  getTempOrder() {
    return this.tempOrder;
  }
  dropTempOrder() {
    this.tempOrder = null;
  }

  reOrder<T extends BaseModel>(id: number) {
    return this._requestService.SendRequest
      ('POST', environment.apiBaseURL + 'orders/' + id + '/re-order', null, null);
  }

  prepareCheckout<T extends BaseModel>(id: number, data: object) {
    return this._requestService.SendRequest
      ('POST', environment.apiBaseURL + 'orders/' + id + '/checkout', data, null);
  }

  updatePaymentStatus<T extends BaseModel>(data: object) {
    return this._requestService.SendRequest
      ('POST', environment.apiBaseURL + 'orders/payment-callbake', data, null);
  }

  updateOrderDetails<T extends BaseModel>(id: number, data: object) {
    return this._requestService.SendRequest('PUT', environment.apiBaseURL + 'orders/' + id + '/details', data, null);
  }
  getOrderDeliveryCost<T extends BaseModel>(orderId: number) {
    return this._requestService.SendRequest
      ('GET', environment.apiBaseURL + 'orders/' + orderId + '/delivery-cost', null, null);
  }

  storeOrdersPaginatedList<T extends BaseModel>(filterObject: FilterObject): Observable<T> {
    filterObject.PageSize = environment.pageSize;
    return this._requestService.SendRequest('POST', environment.apiBaseURL + 'store-orders/filtered-list', filterObject, null);
  }

  storeOrder<T extends BaseModel>(orderNumebr: number, serial: number): Observable<T> {
    return this._requestService.SendRequest('GET', environment.apiBaseURL + 'store-orders/' + orderNumebr + '/series/' + serial, null, null);
  }

  reOrderExistStoreOrder<T extends BaseModel>(data: object, orderNumber: number, serial: number) {
    return this._requestService.SendRequest
      ('POST', environment.apiBaseURL + 'store-orders/' + orderNumber + '/series/' + serial + '/re-order', data, null);
  }

  getThobePrice(productId: any) {
    return this._requestService.SendRequest('GET', environment.apiBaseURL + 'orders/price/' + productId, null, null);
  }
  PaidMaterialAvailability() {
    return this._requestService.SendRequest('GET', environment.apiBaseURL + 'orders/PaidMaterialAvailability', null, null);
  }
}
