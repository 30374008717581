import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import { AuthenticationService } from '../../auth/authentication.service';
import { HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { AuthNoticeService } from '../../auth/auth-notice.service';


@Injectable()
export class RequestService {
    constructor(
        private http: HttpClient, private _router: Router, private authService: AuthenticationService, public authNoticeService: AuthNoticeService) {
    }

    SendRequest(method: string, url: string, data: any, responseType: string): Observable<any> {

        return this.http.request(method, url,
            {
                headers: this.jwt(),
                body: data
            }).pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
    }


    private jwt() {
        // create authorization header with jwt token
        const token = localStorage.getItem('accessToken');
        if (token) {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                // 'cashe': 'false',
                // 'foobar': '' + new Date().getTime() + '',
            });
            return headers;
        }
    }

    private handleError(res: HttpErrorResponse) {
        if (res.status === 500) {
            return throwError(res.error);
        } else if (res.status === 400) {
            return throwError(res.error);
        } else if (res.status === 401 || res.status === 403) {
            this.authService.logout(true);
            return throwError(res.error);
        } else if (res.status === 409 || res.status === 404) {
            return throwError(res.error);
        }
    }

    DownloadFile(url: string) {
        const httpOptions = {
            responseType: 'blob' as 'json',
        };
        return this.http.get(url, httpOptions);
    }

    Upload(url: string, data: FormData, responseType: string): Observable<any> {
        return this.http.post(url, data).pipe(catchError((error, caught) => {
            // intercept the respons error and displace it to the console
            return this.handleError(error);
        }) as any);

	}

	getImage(method: string, url: string, data: any, responseType: string): Observable<Blob> {
        // tslint:disable-next-line:max-line-length
        return this.http.post(url, data, {headers: {'Content-Type': 'application/json'}, responseType: 'blob'}).pipe(catchError((error, caught) => {
            // intercept the respons error and displace it to the console
            return this.handleError(error);
        }) as any);
    }


}
