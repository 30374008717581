import { Injectable } from '@angular/core';
import { RequestService } from '../services/shared/request.service';
import { environment } from '../../../environments/environment';
import { BaseModel } from '../../core/models/baseModel';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { Feature } from '../models/feature';

@Injectable()
export class SettingService {
  constructor(
    private _requestService: RequestService) {
  }
  getPrice<T extends BaseModel>() {
    return this._requestService.SendRequest
      ('GET', environment.apiBaseURL + 'setting/price', null, null);
  }
}
