import { Output, EventEmitter, Injectable } from '@angular/core';
import { OrderService } from '../order.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class HeaderSharedService {
  @Output() cartCount: EventEmitter<number> = new EventEmitter();
  isVerified: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private _orderService: OrderService) {

  }

  updateIsVerified() {
    this.isVerified.next(true);
  }

  setCartCount(count: number) {
    this.cartCount.emit(count);
  }

  getCartCount() {
    return this.cartCount;
  }

  updateCartCount() {
    return this._orderService.penddingCount().subscribe(data => {
      this.setCartCount(data);
    });
  }
}
