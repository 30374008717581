import { Injectable } from '@angular/core';
import { RequestService } from './shared/request.service';
import { environment } from '../../../environments/environment';
import { BaseModel } from '../models/baseModel';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { Feature } from '../models/feature';

@Injectable()
export class ProductService {
	constructor(
		private _requestService: RequestService) {
	}

	checkIfProductsExists<T extends BaseModel>() {
		return this._requestService.SendRequest
			('GET', environment.apiBaseURL + 'products/exists' , null, null);
	}
}
