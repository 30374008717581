// USA
export const locale = {
    lang: 'en',
    data: {
        TRANSLATOR: {
            SELECT: 'Select your language',
        },
        AUTH: {
            GENERAL: {
                BRANCH: 'Branch',
                FROM_STORE: 'Pickup from branch',
                DELIVERY: 'Delivery',
                DELIVERY_NOTE: 'Delivery price : ',
                OR: 'Or',
                SUBMIT_BUTTON: 'Submit',
                NO_ACCOUNT: 'Don\'t have an account?',
                SIGNUP_BUTTON: 'Signup',
                FORGOT_BUTTON: 'Forgot Password',
                RESET_BUTTON: 'Reset Password',
                BACK_BUTTON: 'Back',
                PRIVACY: 'Privacy',
                LEGAL: 'Legal',
                CONTACT: 'Contact',
                WELCOMETXT: 'Welcome in Mashar',
                BACK: 'Back',
                ACTIVATE: 'Your account is not active',
                ACTIVATENOW: 'Activate Now',
                ACTIVATETOORDER: 'Your account is not active , please activate to be able to confirm order',
                PROCEED_TO_PAYMENT: 'Go To Payment Method',
                DELIVERY_INTRUCTIONS_DETAILS: 'Delivery Instructions Details',
            },
            LOGIN: {
                EMAILTXT: 'Email',
                PASSWORDTXT: 'Password',
                FORGOTPASSWORDTXT: 'Forgot Password?',
                NOT_HAVE_ACCOUNT: 'Not has account?',
                CREATE_ACCOUNT: 'Sign up'
            },
            FORGETPASSWORD: {
                TITLE: 'Did you forget your password?',
                Message_Txt: 'Enter your email to get change password mail',
                EMAIL_TXT: 'Email',
                EMAILSENT: 'An e-mail has been sent to you, please check it',
                NOTREGISTERD: 'We can\'t find a user with that username.',
            },
            FORGOT: {
                TITLE: 'Forgotten Password?',
                DESC: 'Enter your email to reset your password',
                NOTREGISTERD: 'We can\'t find a user with that Email',
                EMAILSENT: 'An email was sent , please check your email',
            },
            REGISTER: {
                TITLE: 'Sign Up',
                DESC: 'Enter your details to create your account',
                SUCCESS: 'Your account has been successfuly registered. Please use your registered account to login.',
                NAME_TXT: 'Name',
                EMAILTXT: 'Email',
                PHONE_TXT: 'Phone',
                CITY_TXT: 'City',
                ADDRESS_TXT: 'Address',
                PASSWORD_TXT: 'Password',
                CONFIRM_PASSWORD: 'Confirm Password',
                HAVE_ACCOUNT: 'Have account?',
                LOGIN: 'Login',
                VALIDATION: {
                    REQUIRED_NAME: 'Name is required',
                    Requird_Email: 'Email is required',
                    Invaild_Email: 'Invaild email',
                    REQUIRED_PHONE: 'Phone is required',
                    REQUIRED_BRANCH: 'Branch required',
                    REQUIRED_CITY: 'City is required',
                    REQUIRED_ADDRESS: 'Address is required',
                    REQUIRED_PASSWORD: 'Password is required',
                    REQUIRED_CONFIRM_PASSWORD: 'Confirm password is required',
                    INVALID_CONFIRM_PASSWORD: 'The password doesn\'t match',
                    Invaild_PASSWORD: 'Password must be at least 6 characters'
                }
            },
            RESET: {
                TITLE: 'Reset Password?',
                DESC: 'Enter your new password',
            },
            INPUT: {
                EMAIL: 'Email',
                FULLNAME: 'Fullname',
                PASSWORD: 'Password',
                CONFIRM_PASSWORD: 'Confirm Password',
            },
            VALIDATION: {
                INVALID: '{{name}} is not valid',
                REQUIRED: '{{name}} is required',
                MIN_LENGTH: '{{name}} minimum length is {{min}}',
                AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
                NOT_FOUND: 'The requested {{name}} is not found',
                INVALID_LOGIN: 'wrong email or password',
                CONFIRM_PASSWORD: 'The password doesn\'t match',
                EMAIL_TXT: 'Email is required',
                EMAIL_TXT_INVALID: 'Invaild email',
                BLOCKED: 'Your account was blocked, please contact support for more info.'
            }
        },
        PHONE_VERIFICATION: {
            ACCOUNT_VERIFY: 'Verify account ',
            ADD_CODE: 'Please enter the recieved verification code on your phone ',
            VALIDATION: {
                CODE: 'Please enter code sent to your mobile ',
            },
            VER_CODE: 'Verification code',
            RESEND_CODE: 'The code has been resent ',
        },
        BUTTONS: {
            CONFIRM: 'Confirm',
            RESEND_CODE: 'Resend Code',
        },
        GENERAL: {
            CURRENCY: 'SAR',
            FABRICS_TXT: 'Fabrics',
            CANCEL: 'Cancel',
            DELETE: 'Delete',
            HERE_TXT: 'here',
            RECOMMENDED: 'Recommended',
            ITEMSPERPAGE: 'Number of elements :',
            NEXTPAGELABEL: 'Next',
            PREVIOUSPAGELABEL: 'Previous',
            FIRSTPAGELABEL: 'First page',
            LASTPAGELABEL: 'Last page',
            RANGELABEL: 'of',
            SOON: 'Soon',
            CONFIRM_UPDATE: 'Data updated successfully',
            PAY_ORDER: 'Order send successfully',
            NO_DATA: 'No data',
            CONFIRM: 'Confirm',
            METER: 'm',
            DUEDATE: 'Delivery Date: within {{name}}  Days'
        },
        Header: {
            navbar: {
                HomeTxt: 'Home',
                ProductsTxt: 'Products',
                CustomizeThobeTxt: 'Customize Thobe',
                AboutTxt: 'About Mashar',
                BranchesTxt: 'Branches',
                ContactusTxt: 'Contact Us'
            },
            UserMenu: {
                ProfileTxt: 'Profile',
                OrdersTxt: 'My Orders',
                LogoutTxt: 'Logout',
                LoginTxt: 'Login',
                ActivateTxt: 'Activate Account',
                MEASUREMENTS: 'My measurements',
            }
        },
        Home: {
            bannerBtnTxt: 'Design your thobe',
            NOTE: 'you are not logged in, please login so you can make your first order',
            stepsInfoSection: {
                chooseFabricsTxt: 'Choose fabrics',
                CustomizeThobeDescTxt: 'With Mashar you can design your thobe to get a unique one with a specific feature designed for you.',
                chooseSizeTxt: 'Choose your size',
                startNowBtnTxt: 'Start Now',
                howCustomizeThobe: 'How to make your thobe',
                makeThobeTxt: 'Make your thobe',
                payCreditTxt: 'Pay with credit card',
                receiveThobeTxt: 'Receive your thobe',
                branches: 'Oure branches'
            },
            bestsellingTxt: 'Best Selling',
        },
        footer: {
            copyRightTxt: 'Mashar all rights reserved'
        },
        Product: {
            list: {
                headerTxt: 'Fabrics'
            },
            IMAGE_WARNING: 'Please be aware that the image is not 100% like the real item.',
            Recommended_TXT: 'Recommended fabrics',
            NOT_AVAILABLE: 'Not Available',
            PRODUCT_WARNING: 'Please be aware that the products are not available now. You can connect with the admin',
        },
        contact: {
            contactUstxt: 'Contact Us',
            NameTxt: 'Name',
            RequiredNameTxt: 'Name is required',
            EmailTxt: 'Email',
            RequirdEmailTxt: 'Email is required',
            InvaildEmailTxt: 'Invaild email',
            MessageTxt: 'Message',
            RequirdMsgTxt: 'Message is required',
            SendBtnTxt: 'Send',
            SUCCESS_MSG: 'Message sent successfully'
        },
        CART: {
            TITLE: 'Shopping cart',
            PAYMENT: 'Pay',
            customer_discount: 'Customer Discount',
            VISA: 'VISA, MASTER',
            MADA: 'MADA',
            PC: 'PC',
            PLEASE_WAIT: 'Please Wait',
            VAT: 'Vat',
            TOTAL_BEFORE: 'Total before vat',
            SUCCESS_PAYMENT: 'Payment successed',
            CHOOSE_PAYMENT_METHOD: 'Choose a payment method',
            SUCCESS_PAYMENT_MESSAGE: 'Thanks for shopping. you can monitor your order and checking delivery status from',
            FAILED_PAYMENT: 'Payment failed',
            PAY_ERROR: 'You ca\'t pay order',
            EMPTY_CART_TXT: 'Not have any products in your cart',
            ADD_THOBE: 'Add Thobe',
            ORDER_DETAIL: 'Order details',
            TOTAL_BEFORE_DELIVERY: 'Total before delivery',
            DELIVERY: 'Delivery',
            TOTAL_PRICE: 'Total',
            PAY_NOW: 'Pay now',
            ADD_TO_CART: 'ADD TO CART',
            REORDER: 'Re-order',
            DELIVERY_INSTRUCTION: 'Delivery Instructions',
            THOBE: 'Thobe',
            REQUIRED: 'is required',
            ADD_NEW_THOBE: 'Add new thobe',
            CANCEL_ORDER: 'Cancel order',
            CONFIRM: 'Confirm order',
            QUANTITY_TXT: 'Quantity',
            DELETE_ITEM: 'Delete item',
            DELETE_ITEM_CONFIRM: 'Are you sure to delete this item?',
            DELETING_ITEM: 'Deleting.....',
            CONFIRM_ORDER: 'Your order is confirmed',
            CONFIRM_ORDER_DESC: 'Thanks for shopping. you can monitor your order and checking delivery status from',
            PAYMENT_NOT_WORKING: 'Online payment is not available yet, we will contact your for the order payment ',
            DETAILS: "Show details",
            JOB: 'Job',
        },
        ORDERS: {
            ORDER_DETAILS: 'Order details',
            QUANTITY: 'Quantity: ',
            MY_ORDERS: 'Online orders',
            STORE_ORDERS: 'Stores orders',
            CANCEL: {
                TITLE: 'Cancel order',
                DESCRIPTION: 'Are you sure to cancel this order?',
                WAIT_DESCRIPTION: 'Canceling order ....',
            },
            REORDER: {
                TITLE: 'Re-order',
                DESCRIPTION: 'Are you sure to re-order this thobe?',
                WAIT_DESCRIPTION: 'Re-ordring ....',
            }
        },
        ABOUT: {
            ABOUT_MASHAR: 'About Mashar',
            WHY_MASHAR: 'Why Mashar?',
            WHY_MASHAR_FIRST_PARAGRAPH: 'Mashar Gents Tailoring is one of the most famous names in the world of gents tailoring. Through its branch network, it was able to provide the finest services in connection with men tailoring. This came to him by the huge administrative and technical cadres led by an engineer who loved making the garment, so he invested in it and made it a profession.',
            WHY_MASHAR_SECON_PARAGRAPH: 'That is Eng. Saud bin Ali Al-Saqiyyah, General Manager of the Mashar Foundation, who spoke, explaining that the foundation, from its inception, is in continuous development, seeking the best always, as it prepared for that by bringing in the latest machinery and equipment in addition to importing the finest types of fabrics. Al-Saqiyyah said: Mashar is based on a solid base of technical expertise represented in the cadres of employees, who number more than 300 between an administrator and a technician.',
            NEW_ERA_TITLE: 'A new era for Saudi thobe',
            NEW_ERA_FIRST_PARAGRAPH: 'The Foundation, since its inception and is in continuous development, seeks the best always, as I prepared it for that by bringing in the latest machines and equipment in addition to importing the finest fabrics. Al-Saqiyyah said: Mashar is based on a solid base of technical expertise represented in the cadres of employees, who number more than 300 between an administrator and a technician.',
            NEW_ERA_SECON_PARAGRAPH: 'On our question about Machar\'s interference in developing the concepts of the dress in terms of detail and design, Engineer Saud replied: We always modify, change and develop the concepts of the dress according to our experience in this field in addition to that we are listening ears, we implement the customer\'s desires carefully and offer him advice in case we see that some aspect of the design will harm In the form of the garment and thus the customer is damaged.',
        },
        ORDER_SUMMERY: {
            ADD_OTHER_THOBE: 'Add another thobe',
            NOT_HAS_SIZE: 'You do not have a size registered with us, please go to the nearest branch to take your measurements',
            EDIT_SIZE_TXT: 'To adjust the size, please visit the nearest branch',
            MEASUREMENT_DETAIL: 'Size details',
            LAST_SIZE: 'The last size was measured in',
            LENGTH: 'Length',
            SHOULDER: 'Shoulder',
            WAIST: 'Waist',
            SLEEVE: 'Sleeve',
            NECK: 'Neck',
            CM: 'cm',
            ORDER_SUBMITTED: 'Your thob orders successfully',
            ORDER_SUBMITTED_NOTE: 'We will order your thob soon',
            NOT_HAS_REGISTERED_SIZE: 'You do not have a size registered with us',
            NOT_HAS_REGISTERED_SIZE_DESC: 'Please visit the nearest branch to take a size by a specialized tailor to get the highest accuracy. We will keep the details of your thobe with us until the size is taken from the branch.',
            NAME: 'Product Name',
            PRICE: 'Price',
            QUANTITY: 'Quantity',
            TOTAL: 'Total Price',
        },
        MEASUREMENTS: {
            MY_MEASUREMENTS: 'My measurements',
            MEASUREMENTS_NOTE: 'This garment will be designed according to your measurements: review measurements',
            UNIT: 'cm',
            flat_armfit: 'Armfit',
            flat_back: 'Back',
            flat_chest: 'Chest',
            flat_first_elbow: 'Elbow 1',
            flat_front: 'Front',
            flat_hips: 'Hips',
            flat_neck: 'Neck',
            flat_shoulder: 'Shoulder',
            flat_sleeve: 'Sleeve',
            flat_wrist: 'Wrist',
            flat_waist: 'Waist',
            flat_second_elbow: 'Elbow 2',
        }
    }
};
