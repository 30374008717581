
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: './content/pages/pages.module#PagesModule'
    // loadChildren: 'app/content/pages/pages.module#PagesModule'
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [RouterModule]
})

export class AppRoutingModule { }







