import { Injectable } from '@angular/core';
import { RequestService } from '../shared/request.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class UserService {
    constructor(
        private _requestService: RequestService) {
    }

    getCurrentUser() {
        return this._requestService.SendRequest('GET', environment.apiBaseURL + 'authenticate/user', null, null);
    }
    logOut() {
        return this._requestService.SendRequest('POST', environment.apiBaseURL + 'authenticate/invalidate', null, null);
    }
    block(id) {
        return this._requestService.SendRequest('POST', environment.apiBaseURL + 'block/' + id, null, null);
    }

    unBlock(id) {
        return this._requestService.SendRequest('POST', environment.apiBaseURL + 'unblock/' + id, null, null);
    }

    public verify(data) {
        return this._requestService.SendRequest('POST', environment.apiBaseURL + 'authenticate/verify', data, null);
    }

    public resendCode() {
        return this._requestService.SendRequest('POST', environment.apiBaseURL + 'authenticate/resend-confirmation-code', null, null);
    }


    getCustomerMeasurements(){
        return this._requestService.SendRequest('GET', environment.apiBaseURL + 'user-measurements', null, null);
    }
}
