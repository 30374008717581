import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslationService } from './core/services/translation.service';

// language list
import { locale as enLang } from './config/i18n/en';
import { locale as arLang } from './config/i18n/ar';
import { SplashScreenService } from './core/services/splash-screen.service';
//import * as objectPath from 'object-path';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'my-app';
  @ViewChild('splashScreen', { read: ElementRef })
  splashScreen: ElementRef;
  //require: any;

  constructor(private router: Router, private translationService: TranslationService, private splashScreenService: SplashScreenService) { 
    this.translationService.loadTranslations(arLang, enLang);
  }

  ngOnInit() {
      this.router.events.subscribe((evt) => {
          if (!(evt instanceof NavigationEnd)) {
              return;
          }
          window.scrollTo(0, 0)
      });

      const isArabic = this.translationService.isArabic();
      if (isArabic) {
        require('style-loader!../assets/scss/style-arabic.scss');
      }
  }

  ngAfterViewInit(): void {
		if (this.splashScreen) {
      this.splashScreenService.init(this.splashScreen.nativeElement);
		}
	}

}
