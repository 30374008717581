import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

export interface Locale {
	lang: string;
	data: Object;
}

@Injectable({
	providedIn: 'root'
})
export class TranslationService {
	private langIds: any = [];
	private selectedLanguage: Observable<string>;

	constructor(private translate: TranslateService) {
		// add new langIds to the list
		this.translate.addLangs(['ar']);

		// this language will be used as a fallback when a translation isn't found in the current language
		if (localStorage.getItem('language')) {
			this.translate.setDefaultLang(localStorage.getItem('language'));
		} else {
			this.translate.setDefaultLang('ar');
		}
	}

	public loadTranslations(...args: Locale[]): void {
		const locales = [...args];

		locales.forEach(locale => {
			// use setTranslation() with the third argument set to true
			// to append translations instead of replacing them
			this.translate.setTranslation(locale.lang, locale.data, true);

			this.langIds.push(locale.lang);
		});

		// add new languages to the list
		this.translate.addLangs(this.langIds);
	}

	setLanguage(lang) {
		if (lang) {
			this.translate.use(this.translate.getDefaultLang());
			this.translate.use(lang);
			localStorage.setItem('language', lang);
		}
	}

	public getSelectedLanguage(): Observable<any> {
		return of(localStorage.getItem('language') || this.translate.getDefaultLang());
	}

	public isArabic(): boolean {
		this.selectedLanguage = this.getSelectedLanguage();
		if (this.selectedLanguage['value'] === 'ar') {
			return true;
		} else {
			return false;
		}
	}

	public getTranslatedObject(model:any , key:any):any{
		if(model[key]){
			let column = key + (this.isArabic()?'_ar':'');
			return model[column];
		}
	}

	public translateList(fullList:any , key:any):any{
		if(fullList){
			fullList.forEach(item=>{
				if(item[key]){
					item[key] = this.getTranslatedObject(item, key);
				}
			});
			return fullList;
		}
	}

	public translateListWithSubList(fullList:any , key:any, subList:any , subListkey:any):any{
		if(fullList){
			fullList.forEach(item=>{
				if(item[key]){
					item[key] = this.getTranslatedObject(item, key);
				}
				if(item[subList]){
					item[subList] = this.translateSubLists(item[subList], key,subList,subListkey);
				}
			});
			return fullList;
		}
	}

	public translateSubLists(subList:any, key:any, subListName:any, subListkey:any){
		subList.forEach(subItem => {
			if (subItem[subListName] && subItem[subListName].length > 0){
				subItem[subListName] = this.translateSubLists(subItem[subListName], key,subListName,subListkey);
			}
			if(subItem[key]){
				subItem[key] = this.getTranslatedObject(subItem, key);
			}
		});
		return subList;
	}
}
